module.exports = {
  supportedLanguages: [
    { code: "de", title: "Deutsch" },
    { code: "en", title: "English" },
    { code: "fr", title: "Français" },
  ],
  defaultLanguageCode: "de",
  getLanguageCodes: (data) => data.map((lang) => lang.code),
  getLanguageDataForCode: (data, code) =>
    data.find((lang) => lang.code === code),
  getBasePathForLanguage: (lang) => (lang && lang != "de" ? `/${lang}/` : "/"),
  getTemplateIdForLanguage: (lang) => `page-template-${lang}`,
  getPageTemplateIdForLanguage: (lang) => `page-template-${lang}`,
  getPostTemplateIdForLanguage: (lang) => `post-template-${lang}`,
  getStartPageTemplateIdForLanguage: (lang) => `start-page-${lang}`,
  getSiteSettingsTemplateIdForLanguage: (lang) => `site-settings-${lang}`,
};
